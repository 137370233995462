import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const setUser = (data) => user.value = data;
    const isCompanyType = (type) => user.value?.company?.type === type;
    const showTour = ref(true);
    const isLoggedIn = computed(() => !!user.value);
    const csrfToken = computed(() => user.value?.csrfToken);
    const hasCompletedSetup = computed(() => !!user.value?.company);
    return { user, csrfToken, setUser, isLoggedIn, isCompanyType, hasCompletedSetup, showTour };
});