<template>
  <Component :is="layout">
    <RouterView />
  </Component>
  <Toast position="top-right" />
</template>

<script setup>
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/aura-light-green/theme.css'
import Toast from 'primevue/toast';

import { computed, defineAsyncComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const layout = computed(() => route.meta.private ? Private : Public);

const Private = defineAsyncComponent(() => import('@/layouts/Private.vue'));
const Public = defineAsyncComponent(() => import('@/layouts/Public.vue'));

onMounted(() => {
  // Current location does not match app url (e.g. accessed via pages.dev)
  if(import.meta.env.VITE_APP_URL && window.location.origin !== import.meta.env.VITE_APP_URL) {
    window.location.href = window.location.href.replace(window.location.origin, import.meta.env.VITE_APP_URL);
  }
  console.log("Currently running Dubrink 3.0.20");
});
</script>